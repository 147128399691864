import React from "react";

import FormModal from "../../../../../../common/modal/form_modal";

import { ReactComponent as IconPszok } from "../../../../../../../../resource/img/icons/collection_points/icon_pszok.svg";
import { ReactComponent as IconBattery } from "../../../../../../../../resource/img/icons/collection_points/icon_battery.svg";
import { ReactComponent as IconElectric } from "../../../../../../../../resource/img/icons/collection_points/icon_electric.svg";
import { ReactComponent as IconLightbulb } from "../../../../../../../../resource/img/icons/collection_points/icon_lightbulb.svg";
import { ReactComponent as IconMedicine } from "../../../../../../../../resource/img/icons/collection_points/icon_medicine.svg";
import { ReactComponent as IconSyringe } from "../../../../../../../../resource/img/icons/collection_points/icon_syringe.svg";
import { ReactComponent as IconBio } from "../../../../../../../../resource/img/icons/collection_points/icon_bio.svg";
import { ReactComponent as IconOil } from "../../../../../../../../resource/img/icons/collection_points/icon_oil.svg";
import { ReactComponent as IconTyres } from "../../../../../../../../resource/img/icons/collection_points/icon_tyres.svg";
import { ReactComponent as IconTextiles } from "../../../../../../../../resource/img/icons/collection_points/icon_textiles.svg";
import { ReactComponent as IconFoils } from "../../../../../../../../resource/img/icons/collection_points/icon_foils.svg";
import { ReactComponent as IconRopes } from "../../../../../../../../resource/img/icons/collection_points/icon_ropes.svg";
import { ReactComponent as IconContainerBlack } from "../../../../../../../../resource/img/icons/collection_points/icon_container--black.svg";
import { ReactComponent as IconContainerBlue } from "../../../../../../../../resource/img/icons/collection_points/icon_container--blue.svg";
import { ReactComponent as IconContainerGreen } from "../../../../../../../../resource/img/icons/collection_points/icon_container--green.svg";
import { ReactComponent as IconContainerYellow } from "../../../../../../../../resource/img/icons/collection_points/icon_container--yellow.svg";
import { ReactComponent as IconContainerWhite } from "../../../../../../../../resource/img/icons/collection_points/icon_container--white.svg";
import { ReactComponent as IconContainerCeladon } from "../../../../../../../../resource/img/icons/collection_points/icon_container--celadon.svg";
import { ReactComponent as IconPlasticCap } from "../../../../../../../../resource/img/icons/collection_points/icon_plastic_cap.svg";
import { ReactComponent as IconScrap } from "../../../../../../../../resource/img/icons/collection_points/icon_scrap.svg";

const AllTypesListModal = ({ triggerComponent, headerText, ariaLabel }) => {

    return (
        <FormModal
            triggerComponent={ triggerComponent }
            closeButton={ true }
            headerText={ headerText }
            ariaLabel={ ariaLabel }>

            <div className='c-modal__body modal-size-m'>
                <div className="legend-modal legend-modal--2_columns">
                    <div className="legend-modal--column">
                        <div className='th-hidden-info__row'>
                            <IconPszok/>
                            <div>
                                <p>Punkt Selektywnej Zbiórki Odpadów Komunalnych (PSZOK)</p>
                            </div>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconBattery/>
                            <p>Baterie i Małe Akumulatory</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconElectric/>
                            <p>Elektroodpady</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconLightbulb/>
                            <p>Żarówki</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconMedicine/>
                            <p>Lekarstwa</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconSyringe/>
                            <p>Igły i strzykawki</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconBio/>
                            <p>Odpady biodegradowalne</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconTextiles/>
                            <p>Tekstylia</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconTyres/>
                            <p>Opony rolnicze</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconFoils/>
                            <p>Folie rolnicze</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconRopes/>
                            <p>Sznurki rolnicze</p>
                        </div>
                    </div>
                    <div className="legend-modal--column">
                        <div className='th-hidden-info__row'>
                            <IconContainerGreen/>
                            <p>Szkło</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconContainerGreen/>
                            <p>Szkło kolorowe</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconContainerWhite/>
                            <p>Szkło bezbarwne</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconContainerBlue/>
                            <p>Papier i tektura</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconContainerYellow/>
                            <p>Tworzywa i metale</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconContainerBlack/>
                            <p>Zmieszane odpady komunalne</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconContainerCeladon/>
                            <p>Odpady zielone</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconPlasticCap/>
                            <p>Plastikowe nakrętki</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconOil/>
                            <p>Pojemniki na zużyte oleje jadalne</p>
                        </div>
                        <div className='th-hidden-info__row'>
                            <IconScrap/>
                            <p>Złom</p>
                        </div>
                    </div>
                </div>
            </div>

        </FormModal>
    );
};

export default AllTypesListModal;
